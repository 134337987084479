import { render, staticRenderFns } from "./stripe.vue?vue&type=template&id=5cdb3678&scoped=true&"
import script from "./stripe.vue?vue&type=script&lang=js&"
export * from "./stripe.vue?vue&type=script&lang=js&"
import style0 from "./stripe.vue?vue&type=style&index=0&id=5cdb3678&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cdb3678",
  null
  
)

export default component.exports