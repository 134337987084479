<template>
  <div class="admin-modal">
    <div
      class="admin-modal-wrap"
    >
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('clientOrder.modals.title') }}
        </div>
      </div>
      <VuePerfectScrollbar
        class="admin-modal-wrap-content"
      >
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('clientOrder.modals.country') }}
          </div>
          <selectWithKeyTemplate
            :settings="countrySelectSettings"
            :class="{'error': errors.has('clientCountry')}"
            @changeSelect="changeSelectCountry($event)"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('clientCountry')"
              class="validation"
            >
              {{ errors.first('clientCountry') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('clientOrder.modals.region') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="userAddress.district"
            :class="{'error': errors.has('district')}"
            :errorStatus="errors.has('district')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('district')"
              class="validation"
            >
              {{ errors.first('district') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('clientOrder.modals.city') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="userAddress.city"
            :class="{'error': errors.has('city')}"
            :errorStatus="errors.has('city')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('city')"
              class="validation"
            >
              {{ errors.first('city') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('clientOrder.modals.street') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="userAddress.street"
            :class="{'error': errors.has('street')}"
            :errorStatus="errors.has('street')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('street')"
              class="validation"
            >
              {{ errors.first('street') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('clientOrder.modals.house') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="userAddress.house"
            :class="{'error': errors.has('house')}"
            :errorStatus="errors.has('house')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('house')"
              class="validation"
            >
              {{ errors.first('house') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('clientOrder.modals.flat') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="userAddress.room"
            :class="{'error': errors.has('room')}"
            :errorStatus="errors.has('room')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('room')"
              class="validation"
            >
              {{ errors.first('room') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('clientOrder.modals.postcode') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="userAddress.postcode"
            :class="{'error': errors.has('postcode')}"
            :errorStatus="errors.has('postcode')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('postcode')"
              class="validation"
            >
              {{ errors.first('postcode') }}
            </span>
          </transition>
        </div>
      </VuePerfectScrollbar>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="updateUserAddress()"
          >
            {{ $t('buttons.save') }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="admin-modal__overlay"
    ></div>
    <loader v-if="loader" />
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import textInput from '@/components/fields/textInput';
import validationErrorMessage from '@/locales/validationErrorMessage';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import loader from '@/components/loader';
import countriesApi from '@/api/countries/countriesApi';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import userApi from '@/api/user/userApi';

export default {
  name: 'AddNewServiceModal',
  components: {
    VuePerfectScrollbar,
    textInput,
    loader,
    selectWithKeyTemplate,
  },
  props: {
  },
  data() {
    return {
      userAddressDef: {},
      loader: false,
    };
  },
  computed: {
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
      set(data) {
        this.$store.dispatch('setUserInfo', data);
      },
    },
    userAddress: {
      get() {
        return this.userAddressDef;
      },
      set(data) {
        this.userAddressDef = data;
      },
    },
    defaultCountries: {
      get() {
        return this.$store.getters.defaultCountries;
      },
      set(data) {
        this.$store.dispatch('setDefaultCountriesList', data);
      },
    },
    selectCountriesList() {
      const newArr = [];
      const arrSortByName = sortBy(this.defaultCountries, `name.${this.language}`);
      arrSortByName.forEach((item) => {
        newArr.push({
          text: item.name[this.language],
          key: item.code,
          image: `country/${item.code}.svg`,
        });
      });
      return newArr;
    },
    countrySelectSettings() {
      return {
        optionList: this.selectCountriesList,
        placeholderText: this.$t('countryPage.placeholderSelect'),
        selectedKey: this.userAddress.clientCountry,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
  },
  beforeMount() {
    const dict = {
      en: {
        custom: {
          clientCountry: {
            required: validationErrorMessage.en.inputRequired,
          },
          district: {
            required: validationErrorMessage.en.inputRequired,
          },
          postcode: {
            required: validationErrorMessage.en.inputRequired,
          },
          city: {
            required: validationErrorMessage.en.inputRequired,
          },
          street: {
            required: validationErrorMessage.en.inputRequired,
          },
          room: {
            required: validationErrorMessage.en.inputRequired,
          },
          house: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          clientCountry: {
            required: validationErrorMessage.ru.inputRequired,
          },
          district: {
            required: validationErrorMessage.ru.inputRequired,
          },
          postcode: {
            required: validationErrorMessage.ru.inputRequired,
          },
          city: {
            required: validationErrorMessage.ru.inputRequired,
          },
          street: {
            required: validationErrorMessage.ru.inputRequired,
          },
          room: {
            required: validationErrorMessage.ru.inputRequired,
          },
          house: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'clientCountry', rules: { required: true } });
    this.$validator.attach({ name: 'district', rules: { required: true } });
    this.$validator.attach({ name: 'postcode', rules: { required: true } });
    this.$validator.attach({ name: 'city', rules: { required: true } });
    this.$validator.attach({ name: 'street', rules: { required: true } });
    this.$validator.attach({ name: 'room', rules: { required: true } });
    this.$validator.attach({ name: 'house', rules: { required: true } });
    if (this.defaultCountries.length === 0) {
      countriesApi.getDefaultCountries()
        .then((resp) => {
          this.defaultCountries = resp.data;
        }).catch((err) => {
          console.error(err);
        });
    }
    const {
      clientCountry = '', postcode = '', city = '', district = '', street = '', room = '', house = '',
    } = this.userInfo;
    this.userAddress = {
      clientCountry,
      district,
      postcode,
      city,
      street,
      room,
      house,
    };
    this.language = this.$i18n.locale;
  },
  methods: {
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/${image}`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
    updateUserAddress() {
      const {
        clientCountry,
        district,
        postcode,
        city,
        street,
        room,
        house,
      } = this.userAddress;
      this.$validator.validateAll({
        clientCountry,
        district,
        postcode,
        city,
        street,
        room,
        house,
      }).then((result) => {
        const { _id } = this.userInfo;
        if (result) {
          this.loader = true;
          userApi.updateUser({
            ...this.userAddress,
            _id,
          }).then((resp) => {
            this.loader = false;
            this.userInfo = resp.data;
            this.closeModal(true);
          }).catch((err) => {
            this.loader = false;
            console.error(err);
          });
        }
      });
    },
    changeSelectCountry(key) {
      this.userAddress.clientCountry = key;
    },
    closeModal(addressUpdate = false) {
      this.userAddress = {};
      this.$emit('closeModal', addressUpdate);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
@import "../../sass/transitions";

.admin-modal-wrap {
  max-width: 398px;
  width: 100%;
}

</style>
