<template>
  <div class="personal-area-order">
    <div class="personal-area-order-info">
      <div class="personal-area-order-info__img">
        <img
          :src="getOptionImage(countryFlay)"
          alt="flag"
        >
      </div>
      <div class="personal-area-order-info__text">
        <div
          v-if="countryName"
          class="personal-area-order-info__text-country"
        >
          {{ countryName }}
        </div>
        <div
          v-if="managementForm"
          class="personal-area-order-info__text-form"
        >
          {{ managementForm }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'OrderInfo',
  props: {
    orderInfo: {
      type: Object,
      default: () => {},
    },
    language: {
      type: String,
      default: '',
    },
  },
  computed: {
    order() {
      return this.orderInfo;
    },
    countryFlay() {
      return get(this.order, 'image', '');
    },
    countryName() {
      return `${get(this.order, `name.${this.language}`, '')}`;
    },
    managementForm() {
      return `${get(this.order, `managementForm.${this.language}`, '')}`;
    },
  },
  methods: {
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/${image}`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.personal-area {

  &-order {
    display: flex;
    justify-content: space-between;

    &-info {
      display: flex;

      &__img {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        img {
          border-radius: 50%;
        }
      }

      &__text {
        color: $color-black;

        &-country {
          font-family: $font-global-bold;
        }

        &-form {
          font-size: $font-size-md;
        }
      }
    }
  }
}
</style>
