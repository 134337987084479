<template>
  <div class="admin-modal">
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('orderPage.orderStep') }}
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <div class="admin-modal-wrap-content">
        <div class="admin-modal-wrap-content__title">
          {{ titleStep }}
        </div>
        <div class="admin-modal-wrap-content-desc">
          <div class="admin-modal-wrap-content-desc__item">
            <div class="admin-modal-wrap-content-desc__item-field">
              {{ $t('servicesPage.timing') }}
            </div>
            <div class="admin-modal-wrap-content-desc__item-value">
              {{ timing }} {{ calcNum }}
            </div>
          </div>
          <div class="admin-modal-wrap-content-desc__item">
            <div class="admin-modal-wrap-content-desc__item-field">
              {{ $t('servicesPage.labelCost') }}
            </div>
            <div class="admin-modal-wrap-content-desc__item-value">
              {{ cost }} {{ currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel"
            @click="closeModal"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="reOrder"
          >
            {{ $t('buttons.add') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import ordersApi from '@/api/orders/ordersApi';
import { orderStatus } from '@/constants/orderStatus';
import { get } from 'lodash';

export default {
  name: 'ReOrderModal',
  props: {
    order: {
      type: Boolean,
      default: () => {},
    },
    indexStep: {
      type: Number,
      default: -1,
    },
    item: {
      type: Object,
      default: () => {},
    },
    language: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentOrderStatus() {
      return `${get(this.order.status, 'code', '')}`;
    },
    currency() {
      return this.order.currency;
    },
    titleStep() {
      return this.item.titleStep;
    },
    timing() {
      return this.item.timing;
    },
    cost() {
      return this.item.cost;
    },
    calcNum() {
      const texts = [this.$t('global.dayOne'), this.$t('global.dayAlternative'), this.$t('global.days')];
      const number = this.timing;
      const cases = [2, 0, 1, 1, 1, 2];
      return texts[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    reOrder() {
      Object.keys(this.order.steps).forEach((lang) => {
        const currentStep = this.order.steps[lang][this.indexStep];
        this.order.steps[lang].splice(this.indexStep, 1, {
          ...currentStep,
          isActive: true,
          status: 'IP',
        });
      });
      const order = {
        steps: this.order.steps,
      };
      ordersApi.updateOrder({ id: this.order._id, order })
        .then(() => {
          this.$emit('closeModal');
          const { _id } = this.order;
          const id = _id;
          if (this.currentOrderStatus === orderStatus.CNP) {
            const order = {
              status: orderStatus.IP,
            };
            this.$emit('updateRequest', id, order);
          }
        }).catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 400px;

  &-content {

    &__title {
      background: rgba($color-dodger-blue, .08);
      color: $color-dodger-blue;
      border-radius: $borderRadius;
      padding: 10px;
      text-align: center;
      font-family: $font-global-medium;
      line-height: 1.5;
    }

    &-desc {
      margin-top: 40px;

      &__item {
        display: flex;
        justify-content: space-between;
        font-family: $font-global-medium;

        &-field {
          width: 50%;
          border-right: 1px solid $color-alabaster;
          height: 100%;
          padding: 8px 0;
          color: $color-cornflower-blue;
        }

        &-value {
          width: 50%;
          text-align: right;
          height: 100%;
          padding: 8px 0;
        }
      }

      &__item:first-child {
        border-bottom: 1px solid $color-alabaster;
      }
    }
  }
}

</style>
