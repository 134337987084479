<template>
  <div class="admin-modal">
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <div v-html="closeIcon()"></div>
        </div>
      </div>
      <div class="admin-modal-wrap__content">
        <div class="admin-modal-wrap__image">
          <img
            src="@/assets/images/error.svg"
            alt="error"
          >
        </div>
        <div class="admin-modal-wrap__title">
          {{ $t('error.title') }}
        </div>
        <div class="admin-modal-wrap__message">
          {{ $t(textError) }}
        </div>
      </div>
      <div class="admin-modal-wrap-footer"></div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    >
    </div>
  </div>
</template>

<script>

import { closeIcon } from '@/constants/icons';

export default {
  name: 'ErrorModal',
  props: {
    textError: {
      type: String,
      default: 'error.description',
    },
  },
  data() {
    return {
      closeIcon,
    };
  },
  methods: {
    closeModal() {
      this.$emit('showError', false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 416px;

  &-header {
    border-bottom: none;
  }

  &__image {
    margin-bottom: 10px;
  }

  &__title {
    font-size: $font-size-icon-xd ;
    font-family: $font-global-bold;
    margin-bottom: 6px;
  }

  &__message {
    font-family: $font-global;
    font-size: $font-size-xlg;
    max-width: 215px;
    text-align: center;
    line-height: 1.4;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 5px 44px 5px;
  }
}

</style>
