<template>
  <div
    class="stripe"
    :class="{'open': isOpenStripe}"
  >
    <div
      v-if="isOpenStripe"
      class="stripe__container"
      @click.stop
    >
      <div class="stripe__header">
        <div class="stripe__title">
          {{ $t('global.enterPaymentCardDetails') }}
        </div>
        <div
          class="stripe__close-icon"
          @click="clickOutside"
        >
          <div v-html="closeIconStripe()"></div>
        </div>
      </div>
      <div class="stripe__element">
        <div id="paymentElement" />
      </div>
      <button
        class="stripe__submit"
        :class="{disableBtn : isDisableBtn}"
        :disabled="isDisableBtn"
        @click="payWithCard"
      >
        {{ $t('global.pay') }}
      </button>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import paymentsApi from '@/api/payments/paymentsApi';
import ordersApi from '@/api/orders/ordersApi';
import { STRIPE_PUBLIC_KEY } from '@/constants/paypalLocalClientId';
import { closeIconStripe } from '@/constants/icons';

export default {
  name: 'Stripe',
  directives: {
    ClickOutside,
  },
  props: {
    paymentsForStripe: {
      type: Object,
    },
    isOpenStripe: {
      type: Boolean,
    },
    loader: {
      type: Boolean,
    },
    currentOrder: {
      type: Object,
      default: () => {},
    },
    showChangeStatusPayment: {
      type: Boolean,
      default: true,
    },
    clientSecret: {
      type: String,
    },
  },
  data() {
    return {
      loaded: false,
      stripe: {},
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      isDisableBtn: true,
      paymentElement: null,
      elements: null,
      closeIconStripe,
    };
  },
  computed: {
    loaderDef: {
      get() {
        return this.loader;
      },
      set(data) {
        this.$emit('update:loader', data);
      },
    },
    currentOrderProps: {
      get() {
        return this.currentOrder;
      },
      set(data) {
        this.$emit('update:currentOrder', data);
      },
    },
    paymentsCheckoutData() {
      if (Object.keys(this.currentOrderProps).length) {
        return {
          clientId: this.currentOrderProps.client._id,
          orderId: this.currentOrderProps._id,
          isPrepayment: this.calcPrepaymentCondition,
          lang: this.language,
          paymentService: 'Stripe',
        };
      }
      return false;
    },
    calcPrepaymentCondition() {
      const { isPrepaymentFulfilled, prePayment } = this.currentOrderProps;
      return !isPrepaymentFulfilled && !!prePayment;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.addEventListener('load', this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    clickOutside() {
      this.$emit('closeStripe');
      this.isOpenPaySystem = false;
    },
    updateStripeError(data) {
      if (this.$parent.showErrorPaypal) {
        this.$parent.showErrorStripe(data);
      }
    },
    updateStripeHalf() {
      if (this.$parent.progressBarClickEvent) {
        this.$parent.progressBarClickEvent();
      }
    },
    updateLoader(data) {
      if (this.$parent.updatePaymentLoader) {
        this.$parent.updatePaymentLoader(data);
      }
    },
    updateData(data) {
      if (this.$parent.updateFullData) {
        this.$parent.updateFullData(data, false);
      }
    },
    setLoaded() {
      const key = process.env.VUE_APP_STRIPE_PUBLIC_KEY || STRIPE_PUBLIC_KEY;
      this.stripe = window.Stripe(key);
      const appearance = {
        theme: 'stripe',
        labels: 'above',
        variables: {
          colorPrimary: '#276EF1',
          colorBackground: '#ffffff',
          colorText: '#000',
          colorDanger: '#FF2323',
          fontFamily: 'Ubuntu, system-ui, sans-serif',
          spacingUnit: '4px',
          borderRadius: '4px',
        },
      };

      const elements = this.stripe.elements({
        clientSecret: this.clientSecret,
        appearance,
      });

      const paymentElement = elements.create('payment');

      paymentElement.on('change', (ev) => {
        if (ev.complete) {
          this.isDisableBtn = false;
        }
      });

      paymentElement.mount('#paymentElement');

      this.paymentElement = paymentElement;
      this.elements = elements;
    },
    async payWithCard() {
      this.isDisableBtn = true;
      this.$emit('changePaymentLoader', true);

      try {
        const { paymentIntent, error } = await this.stripe.confirmPayment({
          elements: this.elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${window.location}`,
          },
        });

        if (error) {
          this.updateStripeError(true);
          this.$emit('changePaymentLoader', false);
          this.$emit('textError', error.message);
          this.isDisableBtn = false;
        } else {
          const { id: externalPaymentSystemId } = paymentIntent;

          if (this.showChangeStatusPayment) {
            this.$emit('changeStatusPayment', true);
            this.$emit('changePaymentLoader', true);
          } else {
            this.$emit('closeStripe');
          }

          await paymentsApi.approvePayment({ paymentService: 'Stripe', externalPaymentSystemId });
          this.isDisableBtn = false;
          const timeOut = this.showChangeStatusPayment ? 3000 : 0;
          setTimeout(() => {
            ordersApi.getOrderById(this.currentOrderProps._id)
              .then((resp) => {
                this.updateLoader(false);
                this.updateStripeHalf();
                if (!this.showChangeStatusPayment) {
                  this.$emit('payFull', true);
                } else {
                  this.updateData(resp.data);
                  this.$emit('update:loader', false);
                }
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
                this.$emit('changePaymentLoader', false);
              });
          }, timeOut);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
        this.$emit('changePaymentLoader', false);
        this.updateStripeError(true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.stripe {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  &__title {
    flex: 1 1 auto;
    font: $font-size-dd $font-global-medium;
    color: $color-black;
  }

  &__close-icon {
    flex: 0 0 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $color-dodger-blue;
    cursor: pointer;
  }

  &__container {
    opacity: 0;
    padding: 20px;
    background: $color-white;
    border-radius: 4px;
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    animation: showStripe 0.3s linear;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }

  &__submit {
    border-radius: 4px;
    height: 40px;
    font-size: $font-size-h2;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-dodger-blue;
    color: $color-white;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: $color-blue-ribbon-dark;
    }
  }

  &__submit.disableBtn {
    background-color: $color-gallery;
  }
}
.stripe.open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-black,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes showStripe {
  0% {
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
