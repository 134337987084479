import i18n from '@/i18n';

const paymentMethodDef = [
  {
    id: 1,
    image: [
      {
        id: 1,
        urlImage: 'payment-method-1',
      },
      {
        id: 2,
        urlImage: 'payment-method-2',
      },
    ],
    title: i18n.t('payment.paymentByCard'),
  },
  {
    id: 2,
    image: [
      {
        id: 3,
        urlImage: 'PayPal',
      },
    ],
    title: i18n.t('payment.paymentElectronicMoney'),
  },
  /* {
    id: 3,
    image: [
      {
        id: 4,
        urlImage: 'payment-method-3',
      },
    ],
    title: i18n.t('payment.paymentOnAccount'),
  },
  {
    id: 4,
    image: [
      {
        id: 5,
        urlImage: 'Tether',
      },
    ],
    title: i18n.t('payment.paymentCryptocurrency'),
  }, */
];
export default paymentMethodDef;
