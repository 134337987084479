<template>
  <div class="order">
    <loader v-if="loader" />
    <div
      class="order-header"
    >
      <breadCrumbs
        :items="breadCrumbs"
        :arrowIcon="true"
      />
      <orderInfo
        v-if="Object.keys(orderInfo).length"
        :orderInfo="orderInfo"
        :language="language"
      />
    </div>
    <orderInfoProgressBar
      v-if="Object.keys(orderInfo).length"
      :orderId="orderId"
      :status="orderStatus"
      :userRole="userRole"
      :fullPrice="fullPrice"
      :currency="currency"
      :language="language"
      :value="progressBarStatus"
      :barClass="'progress-bar__blue'"
      :days="progressBarDays"
      :userId="userId"
      :openChat.sync="openChat"
      :currentOrder="currentOrder"
      :procentPayment="procentPayment"
      @progressBarClickEvent="changeAcceptOrder"
      @finishOrder="finishOrder"
      @addProjectManager="addProjectManager"
    />
    <div
      class="table"
    >
      <div class="table-header">
        <div
          class="table-header__order"
        >
          <div class="table-header__order-text">
            {{ $t('orderPage.table.order') }}
          </div>
        </div>
        <div class="table-header__timing">
          {{ $t('orderPage.table.timing') }}
        </div>
        <div class="table-header__price">
          {{ $t('orderPage.table.cost') }}
        </div>
        <div class="table-header__status">
          {{ $t('orderPage.table.status') }}
        </div>
      </div>
      <div class="table-content">
        <div
          v-for="(item, index) in stepList"
          :key="item.stepId"
        >
          <div>
            <div
              class="table-content-row"
            >
              <div
                class="table-content__order"
              >
                <div class="table-content__order-wrap">
                  <div
                    class="table-content__order-index"
                  >
                    {{ index + 1 }}
                  </div>
                  <div class="table-content__order-info">
                    <div>
                      {{ item.titleStep }}
                    </div>
                    <div
                      class="table-content__order_more"
                      :class="{'table-content__order_more_disable': !item.fields.length}"
                      @click="setActiveItem(item.stepId)"
                    >
                      <template
                        v-if="activeItem === item.stepId"
                      >
                        {{ $t('steps.turn') }}
                      </template>
                      <template
                        v-else
                      >
                        {{ $t('steps.details') }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-content__timing">
                <template v-if="!item.isActive">
                  &mdash;
                </template>
                <template v-else>
                  {{ item.timing }}
                  {{ calcDay(item.timing, [$t('global.dayOne'), $t('global.dayAlternative'), $t('global.days')]) }}
                </template>
              </div>
              <div class="table-content__price">
                <template v-if="!item.isActive">
                  &mdash;
                </template>
                <template v-else>
                  {{ item.cost }} {{ currency }}
                </template>
              </div>
              <div class="table-content__status">
                <div
                  v-if="!item.isActive"
                  class="table-content__status-reorder"
                >
                  <span class="table-content__status-info table-content__status-info_individually">
                    {{ $t('orderPage.byYourself') }}
                  </span>
                </div>
                <div
                  class="table-content__status-file"
                >
                  <span
                    class="table-content__status-info"
                    :class="[
                      {
                        'table-content__status-info_process': item.status === 'IP',
                        'table-content__status-info_done': item.status === 'CM'
                      }
                    ]"
                    @click.stop="changeStatusOrder(item.stepId)"
                  >
                    <template v-if="item.status === 'IP'">
                      {{ $t('statuses.inProgress') }}
                    </template>
                    <template v-else-if="item.status === 'CM'">
                      {{ $t('statuses.completed') }}
                    </template>
                  </span>
                  <div
                    v-if="item.status === 'IP'"
                    class="table-content__status-file"
                  >
                    <span
                      v-if="checkLoadingFiles"
                      class="table-content__status-text"
                    >
                      <Upload
                        class="upload-file"
                        :popupDown="false"
                        :multiple="true"
                        :upload="true"
                        :iconText="true"
                        @upload="uploadAllFiles($event, item.stepId, index)"
                      />
                    </span>
                  </div>
                </div>
                <transition name="fade-el">
                  <div
                    v-if="isVisibleChangeOrderModal === item.stepId"
                    ref="modal"
                    v-click-outside="closeWindow"
                    class="order-modal-status"
                  >
                    <div
                      class="order-modal-status__item order-modal-status__item_process"
                      @click="changeStatus(item, 'IP')"
                    >
                      {{ $t('statuses.inProgress') }}
                    </div>
                    <div
                      class="order-modal-status__item order-modal-status__item_done"
                      @click="changeStatus(item, 'CM')"
                    >
                      {{ $t('statuses.completed') }}
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <transition
              name="fade-el"
              mode="out-in"
            >
              <div
                v-if="activeItem === item.stepId"
                class="table-content-more"
              >
                <div
                  v-if="item.documents.length"
                  class="table-content-more-section"
                >
                  <div
                    v-for="file in item.documents"
                    :key="file.fieldId"
                    class="table-content-file"
                  >
                    <template v-if="file.fileInfo">
                      <template v-if="!file.fileInfo.url">
                        <div class="button-action__content">
                          <div class="button-action__left">
                            <div class="button-action__icon">
                              <i class="ub-icon-file-1"></i>
                            </div>
                          </div>
                          <div class="button-action__right">
                            <div
                              class="button-action__top"
                              :class="{'button-action__top_center': file.fileInfo._id}"
                            >
                              <div class="button-action__name">
                                {{ file.fileInfo.originalName }}
                              </div>
                              <div class="button-action__percent">
                              </div>
                            </div>
                            <div
                              class="button-action__bottom"
                            >
                              <div class="button-action-progress">
                                <span class="button-action-progress__line"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <i class="ub-icon-file-1"></i>
                        <a
                          class="table-content-file__link"
                          :href="file.fileInfo.url"
                        >
                          {{ file.fileInfo.originalName }}
                        </a>
                      </template>
                    </template>
                  </div>
                </div>
                <div class="table-content-more-section">
                  <div
                    v-for="file in item.fields"
                    :key="file.fieldId"
                    class="table-content-file"
                  >
                    <div
                      v-if="showElement(file)"
                      class="table-content-elem"
                    >
                      <ElementDefault
                        :zIndexEl="true"
                        :step="item"
                        :element.sync="file"
                        :customClass="render"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="table-amount">
        <div
          class="table-amount__order"
        >
          <div class="table-amount__order-text">
            {{ $t('global.total') }}
          </div>
        </div>
        <div class="table-amount__timing">
          {{ allDays }} {{ calcNum }}
        </div>
        <div class="table-amount__price">
          {{ fullPrice }} {{ currency }}
        </div>
        <div class="table-amount__status"></div>
      </div>
    </div>
    <chat
      v-if="false"
      :order="currentOrder"
      :openChat.sync="openChat"
      :chatId.sync="chatId"
    />
  </div>
</template>

<script>
import {
  orderStatus,
  managerConfirmedNotPayedOrder,
  managerConfirmedPayedOrder,
  requestDocuments,
  receiveDocuments,
  completeAllOrderSteps,
  acceptWorkWithFullyPayedOrder,
  acceptedProjectRequireLastPay,
  notAcceptPartnerWork,
  managerConfirmedLastPayment,
  managerFinishedProject,
  sendPayedProjectDocuments,
} from '@/constants/orderStatus';
import {
  filter, find, get,
} from 'lodash';
import ClickOutside from 'vue-click-outside';
import ordersApi from '@/api/orders/ordersApi';
import Upload from '@/components/Upload';
import queryString from 'query-string';
import breadCrumbs from '@/components/landing/breadCrumbs';
import orderInfo from '@/components/order/orderInfo';
import orderInfoProgressBar from '@/components/order/orderInfoProgressBar';
import loader from '@/components/loader';
import chat from '@/components/chat/chat';
import chatApi from '@/api/chat/chatApi';
import ElementDefault from '@/components/elements/DefaultTemplate';
import { MANAGER } from '@/constants/roleType';
import NEW_UPLOAD_FILE from '@/preset/elements/newUploadFile';

export default {
  name: 'AdminOrder',
  components: {
    breadCrumbs,
    orderInfo,
    orderInfoProgressBar,
    chat,
    loader,
    ElementDefault,
    Upload,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      ordersList: [],
      loadFiles: [orderStatus.IP],
      isVisibleChangeOrderModal: -1,
      activeItem: -1,
      loader: false,
      currentOrderDef: {},
      orderInfoDef: {},
      queryParamsIdId: {
        id: '',
      },
      openChat: false,
      chatId: '',
      render: 'render',
    };
  },
  computed: {
    checkLoadingFiles() {
      return this.loadFiles.includes(this.codeStatus);
    },
    isManager() {
      return this.userRole === MANAGER;
    },
    procentPayment() {
      return get(this.currentOrder, 'prePayment', null);
    },
    userId() {
      return get(this.currentOrder, '_id', '');
    },
    currency() {
      return this.currentOrder.currency;
    },
    showChat() {
      const { _id } = this.currentOrder;
      return !!_id;
    },
    completedSteps() {
      return this.stepList.filter((item) => item.isActive);
    },
    allDays() {
      const arr = this.stepList.filter((item) => item.isActive)
        .map((el) => (el.timing));
      return arr.reduce((acc, val) => acc + val, 0);
    },
    fullPrice() {
      const arr = this.stepList.filter((item) => item.isActive)
        .map((el) => (el.cost));
      return arr.reduce((acc, val) => acc + val, 0);
    },
    calcNum() {
      const texts = [this.$t('global.dayOne'), this.$t('global.dayAlternative'), this.$t('global.days')];
      const number = this.allDays;
      const cases = [2, 0, 1, 1, 1, 2];
      return texts[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    progressBarDays() {
      const arr = this.stepList.filter((item) => item.isActive && item.status !== 'CM')
        .map((el) => ({
          timing: el.timing,
          status: el.status,
        }));
      return arr.reduce((acc, obj) => acc + +obj.timing, 0);
    },
    stepList() {
      return get(this.currentOrder, `steps.${this.language}`, []);
    },
    progressBarStatus() {
      const arr = this.stepList.filter((item) => item.isActive && item.status === 'CM')
        .map((el) => ({
          isActive: el.isActive,
          status: el.status,
        }));
      const fullProcent = this.completedSteps.length;
      if (arr.length) {
        const result = (arr.length * 100) / fullProcent;
        if (result > 100) {
          return 100;
        }
        return result;
      }
      return 0;
    },
    orderStatus() {
      const { status = {} } = this.currentOrder;
      return status;
    },
    orderText() {
      return this.$t('orderPage.order');
    },
    orderId() {
      return this.orderInfo.orderId;
    },
    breadCrumbs() {
      if (this.isManager) {
        return [
          { name: this.$t('orderPage.ordersList'), route: '/orders-list' },
          { name: `${this.orderText} № ${this.orderInfo.orderId}`, route: '/orders' },
        ];
      }
      return [
        { name: this.$t('orderPage.ordersList'), route: '/admin/orders-list' },
        { name: `${this.orderText} № ${this.orderInfo.orderId}`, route: '/admin/orders' },
      ];
    },
    orderInfo: {
      get() {
        return this.orderInfoDef;
      },
      set(data) {
        this.orderInfoDef = data;
      },
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      return this.userInfo.role;
    },
    currentOrder: {
      get() {
        return this.currentOrderDef;
      },
      set(data) {
        this.currentOrderDef = data;
      },
    },
    language() {
      return this.$i18n.locale;
    },
    orderStatusInfo() {
      const { status = {} } = this.currentOrder;
      return status;
    },
    defaultCountries: {
      get() {
        return this.$store.getters.defaultCountries;
      },
      set(data) {
        this.$store.dispatch('setDefaultCountriesList', data);
      },
    },
  },
  beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const { id } = parsed;
    this.loader = true;
    ordersApi.getOrderById(id)
      .then((resp) => {
        this.loader = false;
        this.currentOrder = resp.data;
        const { country: { code, name }, managementForm, orderId } = resp.data;
        this.orderInfo = {
          code,
          name,
          managementForm,
          image: `country/${code}.svg`,
          orderId,
        };
      })
      .catch(() => {
        this.loader = false;
        this.$router.push({
          name: 'page404',
          query: {},
        });
      });
  },
  methods: {
    closeWindow() {
      this.isVisibleChangeOrderModal = -1;
    },
    changeStatus(item, statusCondition) {
      const { steps: stepsInOrder, _id: id } = this.currentOrder;
      const steps = {
        ...stepsInOrder,
      };
      Object.keys(steps).forEach((key) => {
        const stepsList = steps[key];
        const currentStep = stepsList.find((el) => el.stepId === item.stepId);
        if (currentStep) {
          currentStep.status = statusCondition;
        }
      });
      const { projectExecutor, isFullPaymentFulfilled, status: { code } } = this.currentOrder;
      let transition = '';
      if (projectExecutor === 'partner') {
        transition = completeAllOrderSteps;
      } else if (projectExecutor === 'projectManager'
          && isFullPaymentFulfilled) {
        transition = acceptWorkWithFullyPayedOrder;
      } else if (projectExecutor === 'projectManager'
          && !isFullPaymentFulfilled) {
        transition = acceptedProjectRequireLastPay;
      }
      if (this.progressBarStatus !== 100 && code === orderStatus.ALL_STEPS_COMPLETED) {
        transition = notAcceptPartnerWork;
      }
      if (projectExecutor === 'projectManager') {
        if (code === orderStatus.DOCUMENTS_RECEIVED
            && isFullPaymentFulfilled) {
          transition = acceptWorkWithFullyPayedOrder;
        } else {
          transition = acceptedProjectRequireLastPay;
        }
      }
      const order = {
        steps,
        transition,
      };
      this.isVisibleChangeOrderModal = -1;
      if (this.progressBarStatus === 100) {
        this.updateRequest(id, order);
      }
    },
    uploadAllFiles(item, id, index) {
      this.activeItem = id;
      item.forEach((el) => {
        const newFile = NEW_UPLOAD_FILE();
        const { steps } = this.currentOrder;
        Object.keys(steps).forEach((key) => {
          const step = steps[key][index];
          if (step) {
            step.documents.push(
              {
                ...newFile,
                fileInfo: {
                  ...newFile.fileInfo,
                  originalName: el.name,
                },
              },
            );
            const fileFull = this.setFileData(el);
            this.uploadFiles(fileFull, newFile.fieldId, id, index);
          }
        });
      });
    },
    showElement(el) {
      if (!el.parentId.length) {
        return true;
      }
      this.stepList.forEach((step) => {
        this.$set(step, 'isVisible', true);
      });
      const stepListFields = this.stepList.reduce((acc, step) => {
        step.fields.forEach((field) => {
          field.stepId = step.stepId;
        });
        return [...acc, ...step.fields];
      }, []);
      const parentElements = filter(stepListFields, (field) => el.parentId.includes(field.fieldId));
      if (!find(parentElements, (element) => element.checked)) {
        if (!el.type) {
          this.setIsVisibleStep(el, false);
        }
        return false;
      }
      const showStatus = parentElements.some((parentElement) => {
        if (parentElement.parentId.length) {
          return this.showChildElement(parentElement);
        }
        const { stepId } = parentElement;
        const parensStep = this.stepList.find((step) => step.stepId === stepId);
        return parensStep.isVisible ? parentElement.checked : false;
      });
      if (!el.type) {
        this.setIsVisibleStep(el, showStatus);
      }
      return showStatus;
    },
    setIsVisibleStep(el, isVisible) {
      const { stepId } = el;
      Object.keys(this.stepsInfo[this.language]).forEach((key) => {
        const currentStep = find(this.stepsInfo[this.language][key], (item) => item.stepId === stepId);
        currentStep.isVisible = isVisible;
      });
    },
    calcDay(number, time) {
      const cases = [2, 0, 1, 1, 1, 2];
      return time[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    updateRequest(id, order) {
      this.loader = true;
      ordersApi.updateOrderById({ id, order })
        .then((resp) => {
          this.loader = false;
          this.currentOrder = resp.data;
          if (this.isVisibleChangeOrderModal !== -1) {
            this.isVisibleChangeOrderModal = -1;
          }
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    changeStatusOrder(id) {
      this.isVisibleChangeOrderModal = id;
    },
    setActiveItem(id) {
      const result = this.activeItem === id ? this.activeItem = -1 : this.activeItem = id;
      return result;
    },
    changeAcceptOrder() {
      const order = {};
      const {
        isPrepaymentFulfilled, prePayment, _id, status: { code }, isFullPaymentFulfilled,
      } = this.currentOrder;
      const isOrderNotPayed = !isPrepaymentFulfilled && prePayment !== 0;
      order.transition = isOrderNotPayed ? managerConfirmedNotPayedOrder : managerConfirmedPayedOrder;
      if (code === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
        order.transition = requestDocuments;
      } else if (code === orderStatus.DOCUMENTS_REQUESTED) {
        order.transition = receiveDocuments;
      } else if (code === orderStatus.ALL_STEPS_COMPLETED) {
        order.transition = isFullPaymentFulfilled
          ? acceptWorkWithFullyPayedOrder
          : acceptedProjectRequireLastPay;
      } else if (code === orderStatus.LAST_PAYMENT_PAYED) {
        order.transition = managerConfirmedLastPayment;
      } else if (code === orderStatus.LAST_PAYMENT_CONFIRMED) {
        order.transition = sendPayedProjectDocuments;
      }
      this.loader = true;
      this.updateRequest(_id, order);
    },
    sendSystemChatMessage() {
      const {
        client: {
          _id, clientCountry, street, city, postcode, district, room, house,
        },
      } = this.currentOrder;
      const country = this.defaultCountries.find((item) => item.code === clientCountry);
      const countryName = get(country, `name.${this.language}`);
      const address = `${postcode}, ${countryName}, ${district}, ${city}, ${street} ${house}, ${room}`;
      const newMessage = {
        message: address,
        senderId: _id,
        chatId: this.chatId,
        isRead: [_id],
        isSystem: true,
        recipientId: this.userInfo._id,
      };
      chatApi.addMessage(newMessage)
        .then(() => {
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addProjectManager() {
      const { _id } = this.currentOrder;
      const order = {};
      order.projectExecutor = 'projectManager';
      this.loader = true;
      this.updateRequest(_id, order);
    },
    finishOrder() {
      const { _id } = this.currentOrder;
      const order = {};
      order.transition = managerFinishedProject;
      this.loader = true;
      this.updateRequest(_id, order);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.order {
  height: 100%;
  width: 100%;

  &-modal-status {
    position: absolute;
    top: 34px;
    min-width: 120px;
    min-height: 78px;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background: $color-white;
    z-index: 1;
    padding: 3px 0;

    &__item {
      padding: 12px;
      cursor: pointer;
      transition: background-color .15s ease-in;
      text-align: left;

      &_done {
        color: $color-green;
      }

      &_process {
        color: $color-dodger-blue;
      }

      &:hover {
        background: $color-alabaster;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &-info {
      display: flex;

      &__img {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        img {
          border-radius: 50%;
        }
      }

      &__text {
        color: $color-black;

        &-country {
          font-family: $font-global-bold;
        }

        &-form {
          font-size: $font-size-md;
        }
      }
    }
  }

  &__text {
    font: $font-size-base $font-global;
  }

  .cell-list {
    z-index: 0;
  }

  .table {
    margin-top: 25px;
    user-select: none;
    min-width: 1155px;

    &-header {
      width: 100%;
      background: $color-white;
      box-shadow: 0 0 8px rgba($color-black, .04);
      border-radius: $borderRadius;
      color: $color-dodger-blue;
      font: $font-size-base $font-global-medium;
      display: flex;
      justify-content: flex-start;
      padding: 14px 15px;

      &__order {
        width: 40%;
        flex-grow: 1;

        &-text {
          padding-left: 29px;
        }
      }

      &__timing {
        width: 15%;
        text-align: center;
      }

      &__price {
        width: 15%;
        text-align: right;
      }

      &__status {
        width: 30%;
        padding-left: 11%;
        min-width: 450px;
      }
    }

    &-content {
      width: 100%;

      &-elem {
        width: 100%;
      }

      &-file {
        display: flex;
        align-items: center;
        margin-bottom: 19px;

        &:last-child {
          margin-bottom: 0;
        }

        &__link {
          text-decoration: none;
          color: $color-black;
        }

        &__remove {
          color: $color-dodger-blue;
          margin-left: 5px;
          transform: rotate(45deg);
          cursor: pointer;
        }

        .ub-icon-file-1 {
          color: $color-dodger-blue;
          font-size: $font-size-icon-xd;
        }
      }

      &-more {
        background: $color-alabaster;
        box-shadow: 0 0 8px rgba($color-black, .04);
        border-radius: 0 0 $borderRadius $borderRadius;

        &-section {
          padding: 20px 36px;
          border-bottom: 1px solid $color-gallery;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      &__status {
        display: flex;
        text-align: center;
        padding-left: 11%;
        position: relative;

        &-file {
          position: relative;
          font: $font-size-base $font-global-medium;
          color: $color-dodger-blue;
          height: 29px;
          display: flex;
          align-items: center;

          i {
            margin-right: 6px;
            font-size: $font-size-xlg;
          }
        }

        &-input {
          width: 100%;
          position: absolute;
          max-width: 140px;
          height: 20px;
          left: 0;
          top: 5px;
          cursor: pointer;
          opacity: 0;
        }

        &-reorder {
          font: $font-size-base $font-global-medium;
          color: $color-dodger-blue;
          height: 29px;
          display: flex;
          align-items: center;

          i {
            margin-right: 6px;
            font-size: $font-size-xlg;
          }
        }

        &-text {
          cursor: pointer;
          transition: color .15s ease-in;
          min-width: 160px;
          display: flex;
          align-items: center;

          &:hover {
            color: $color-denim;
          }
        }

        &-info {
          border-radius: $borderRadius;
          padding: 4px 8px;
          color: $color-silver-chalice;
          display: flex;
          align-items: center;
          margin-right: 32px;
          font: $font-size-base $font-global;
          height: 28px;
          cursor: default;

          &_individually {
            background: $color-alabaster;
            transition: background-color .15s ease-in;
          }

          &_process {
            background: rgba($color-dodger-blue, .08);
            color: $color-dodger-blue;
            transition: background-color .15s ease-in;
          }

          &_done {
            background: rgba($color-green, .08);
            color: $color-green;
            transition: background-color .15s ease-in;
          }
        }
      }

      &-row {
        display: flex;
        padding: 18px 15px;
        background: $color-white;
        box-shadow: 0 0 8px rgba($color-black, .04);
        border-radius: $borderRadius;
        margin-top: 8px;
        min-height: 73px;
      }

      &__order {
        width: 40%;
        flex-grow: 1;

        &-wrap {
          display: flex;
        }

        &-index {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: rgba($color-dodger-blue, .1);
          display: flex;
          justify-content: center;
          align-items: center;
          font: $font-size-sm $font-global-medium;
          color: $color-dodger-blue;
          margin-right: 10px;
        }

        &_more {
          font: $font-size-md $font-global-medium;
          color: $color-dodger-blue;
          cursor: pointer;
          margin-top: 6px;

          &_disable {
            pointer-events: none;
            color: $color-gallery;
          }
        }
      }

      &__timing {
        width: 15%;
        text-align: center;
      }

      &__price {
        width: 15%;
        text-align: right;
      }

      &__status {
        width: 30%;
        padding-left: 11%;
        min-width: 450px;
      }
    }

    &-amount {
      margin-top: 8px;
      width: 100%;
      background: $color-white;
      box-shadow: 0 0 8px rgba($color-black, .04);
      border-radius: $borderRadius;
      height: 48px;
      color: $color-dodger-blue;
      font: $font-size-base $font-global-bold;
      padding: 14px 15px;
      display: flex;
      align-items: center;

      &__order {
        width: 40%;
        flex-grow: 1;

        &-text {
          padding-left: 29px;
        }
      }

      &__timing {
        width: 15%;
        text-align: center;
      }

      &__price {
        width: 15%;
        text-align: right;
      }

      &__status {
        width: 30%;
        padding-left: 11%;
        min-width: 450px;
      }
    }
  }

  .button-action {

    &__name {
      color: $color-black;
      font-size: $font-size-base;
    }

    &__content {
      padding: 0;
    }

    &__icon i {
      font-size: $font-size-icon-xd!important;
    }
  }
}
</style>
