<template>
  <section class="orders">
    <loader v-if="loaderPayment" />
    <div
      class="orders__wrapper"
    >
      <div class="service-registration">
        <div class="service-registration__title">
          {{ $t('payment.serviceRegistration') }}
        </div>
      </div>
      <div
        v-if="!successfulPayment"
        class="payment__content"
      >
        <div class="payment-method">
          <div class="payment-method__title">
            {{ $t('payment.paymentMethod') }}
          </div>
          <div class="payment-method__list">
            <div
              v-for="method in paymentMethod"
              :key="method.id"
              class="payment-method-item"
              :class="{'payment-method-item_active': method.id === paymentMethodActive}"
              @click="toggleMethodActive(method.id)"
            >
              <div class="payment-method-item__top">
                <div
                  v-for="image in method.image"
                  :key="image.id"
                  class="payment-method-item__image"
                >
                  <img
                    :src="getOptionImage(image.urlImage)"
                    :alt="image.urlImage"
                  >
                </div>
                <div
                  v-if="method.id === paymentMethodActive"
                  class="payment-method-item__status"
                >
                  <i class="ub-icon-check-mark-select"></i>
                </div>
              </div>
              <div class="payment-method-item__title">
                {{ method.title }}
              </div>
              <div
                class="payment-method-item__description"
                v-html="method.text"
              >
              </div>
            </div>
          </div>
        </div>

        <div class="payment-info">
          <template v-if="paymentMethodActive === 1">
            <paySystem
              :activePaySystem="activePaySystem"
              @setActiveSystemPayment="setActiveSystemPayment"
            />
          </template>

          <div
            v-for="step in stepsInfoActive"
            :key="step.id"
            class="payment-info-item"
          >
            <div class="payment-info-item__left">
              <div class="payment-info-item__title">
                {{ step.titleStep }}
              </div>
            </div>
            <div class="payment-info-item__right">
              <div class="payment-info-item__days">
                <span>
                  {{ step.timing }}
                  {{ calcNum(step.timing, [$t('global.dayOne'), $t('global.dayAlternative'), $t('global.days')]) }}
                </span>
              </div>
              <div class="payment-info-item__sum">
                {{ step.cost }} {{ currentOrderProps.currency }}
              </div>
            </div>
          </div>
          <div
            class="payment-info-item"
          >
            <div class="payment-info-item__left">
              <div class="payment-info-item__title">
                {{ $t('steps.total') }}
              </div>
            </div>
            <div class="payment-info-item__right">
              <div class="payment-info-item__days">
                <span>
                  {{ fullTiming }}
                  {{ calcNum(fullTiming, [$t('global.dayOne'), $t('global.dayAlternative'), $t('global.days')]) }}
                </span>
              </div>
              <div class="payment-info-item__sum">
                {{ fullCost }} {{ currentOrderProps.currency }}
              </div>
            </div>
          </div>
          <div
            class="payment-info-item payment-info-item_prepayment"
          >
            <div class="payment-info-item__left">
              <div class="payment-info-item__title">
                {{ $t('steps.prepayment') }} {{ currentOrderProps.prePayment }} %
              </div>
            </div>
            <div class="payment-info-item__right">
              <div class="payment-info-item__prepayment">
                {{ currentOrderProps.prepaymentAmount }} {{ currentOrderProps.currency }}
              </div>
            </div>
          </div>
          <div
            class="payment-info-item payment-info-item_finish"
          >
            <div class="payment-info-item__left">
              <div class="payment-info-item__title">
                <checkBox
                  :value.sync="offer"
                  :checkboxText="$t('payment.offer.offerText') + ' ' + $t('payment.offer.offerLink')"
                  @changeCheckBox="changeCheckBox"
                />
              </div>
            </div>

            <div class="payment-info-item__right">
              <button
                v-if="paymentMethodActive > 2"
                class="ub-btn ub-btn_primary payment-info-item__btn"
                :disabled="!offer"
                :class="{'ub-btn_disabled': !offer, 'btn_dis': !offer}"
                @click.stop="showAnyWayOfPay"
              >
                {{ $t('global.pay') }}
              </button>
              <button
                v-else-if="paymentMethodActive === 1 && activePaySystem > 2"
                class="ub-btn ub-btn_primary payment-info-item__btn"
                :disabled="!offer"
                :class="{'ub-btn_disabled': !offer, 'btn_dis': !offer}"
                @click.stop="showPayPal"
              >
                {{ $t('global.pay') }}
              </button>

              <button
                v-if="paymentMethodActive === 1 && activePaySystem === 2"
                class="ub-btn ub-btn_primary payment-info-item__btn"
                :disabled="!offer"
                :class="{'ub-btn_disabled': !offer, 'btn_dis': !offer}"
                @click.stop="showStripe"
              >
                {{ $t('global.pay') }}
              </button>

              <div v-if="paymentMethodActive === 1 && activePaySystem === 2 && isOpenStripe">
                <Stripe
                  :currentOrder.sync="currentOrderProps"
                  :isOpenStripe="isOpenStripe"
                  :loader.sync="loaderPayment"
                  :clientSecret="clientSecret"
                  @closeStripe="closeStripe"
                  @textError="getTextErrorPayment"
                  @changeStatusPayment="changeStatusPayment"
                  @progressBarClickEvent="progressBarClickEvent"
                  @changePaymentLoader="changePaymentLoader"
                />
              </div>

              <div
                v-if="paymentMethodActive === 2 || (paymentMethodActive === 1 && activePaySystem === 1)"
                class="paypal_container-disable"
              >
                <div
                  :class="{'paypal_wrapper-disable': !offer, 'paypal_wrapper-not-disable': offer}"
                >
                  <div
                    class="ub-btn ub-btn_primary payment-info-item__btn fakeBtn"
                    :class="{'ub-btn_disabled fakeBtn-disable': !offer}"
                  >
                    {{ $t('global.pay') }}
                  </div>
                  <paypal
                    v-if="paymentMethodActive === 2"
                    :loader.sync="loaderPayment"
                    :colorBtnPayPal="'blue'"
                    :fundingSource="'paypal'"
                    :currentOrder.sync="currentOrderProps"
                    @textError="getTextErrorPayment"
                    @changeStatusPayment="changeStatusPayment"
                    @closePayPal="closePayPal"
                    @progressBarClickEvent="progressBarClickEvent"
                    @changePaymentLoader="changePaymentLoader"
                  />
                  <div v-else>
                    <paypal
                      :loader.sync="loaderPayment"
                      :fundingSource="'card'"
                      :currentOrder.sync="currentOrderProps"
                      @textError="getTextErrorPayment"
                      @changeStatusPayment="changeStatusPayment"
                      @closePayPal="closePayPal"
                      @progressBarClickEvent="progressBarClickEvent"
                      @changePaymentLoader="changePaymentLoader"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition
        name="fade-el"
        mode="out-in"
        appear
      >
        <paymentResult
          v-if="successfulPayment"
          :loader.sync="loaderPayment"
          :currentOrder.sync="currentOrderProps"
        />
      </transition>
      <transition
        name="fade-content"
        mode="out-in"
        appear
      >
        <errorModal
          v-if="errorOrder"
          @showErrorPaypal="showErrorPaypal"
        />
        <errorModal
          v-if="errorOrderStripe"
          :textError="textErrorPayment"
          @showError="showErrorStripe"
        />
      </transition>
    </div>
  </section>
</template>

<script>
import paymentMethodDef from '@/constants/paymentMethod';
import checkBox from '@/components/fields/checkBox';
import paypal from '@/components/payment/paypal';
import loader from '@/components/loader';
import paymentResult from '@/components/payment/paymentResult';
import paySystem from '@/components/paySystem/paySystem';
import Stripe from '@/components/payment/stripe';
import errorModal from '@/components/modals/errorModal';
import paymentsApi from '@/api/payments/paymentsApi';

export default {
  name: 'Orders',
  components: {
    checkBox,
    paypal,
    loader,
    paymentResult,
    errorModal,
    paySystem,
    Stripe,
  },
  props: {
    currentOrder: {
      type: Object,
      default: () => {},
    },
    stepsInfo: {
      type: [Array, Object],
      default: () => [],
    },
    loader: {
      type: Boolean,
    },
  },
  data() {
    return {
      errorOrder: false,
      errorOrderStripe: false,
      textErrorPayment: '',
      loaderPayment: false,
      openPayPal: false,
      isOpenStripe: false,
      isOpenModalPayOfCards: false,
      offer: false,
      paymentMethodActive: 1,
      activePaySystem: 1,
      paymentMethod: paymentMethodDef,
      successfulPayment: false,
      paymentsForStripe: {},
      clientSecret: '',
    };
  },
  computed: {
    currentOrderProps: {
      get() {
        return this.currentOrder;
      },
      set(data) {
        this.$emit('update:currentOrder', data);
      },
    },
    loaderDef: {
      get() {
        return this.loader;
      },
      set(data) {
        this.$emit('update:loader', data);
      },
    },
    stepsInfoActive() {
      if (Object.keys(this.stepsInfo).length) {
        if (this.stepsInfo[this.language].length) {
          return this.stepsInfo[this.language].filter((item) => item.isActive);
        }
      }
      return [];
    },
    fullTiming() {
      if (Object.keys(this.stepsInfo).length && this.stepsInfoActive.length) {
        return this.stepsInfoActive.reduce((acc, time) => acc + +time.timing, 0);
      }
      return false;
    },
    fullCost() {
      if (Object.keys(this.stepsInfo).length && this.stepsInfoActive.length) {
        return this.stepsInfoActive.reduce((acc, time) => acc + +time.cost, 0);
      }
      return false;
    },
    language() {
      return this.$i18n.locale;
    },
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
      set(data) {
        this.$store.dispatch('setUserInfo', data);
      },
    },
    calcPrepaymentCondition() {
      const { isPrepaymentFulfilled, prePayment } = this.currentOrderProps;
      return !isPrepaymentFulfilled && !!prePayment;
    },
  },
  methods: {
    setActiveSystemPayment(id) {
      this.activePaySystem = +id;
    },
    progressBarClickEvent() {
      this.$emit('progressBarClickEvent');
    },
    updateFullData(data, bool) {
      this.changeCurrentOrders(data);
      this.changeStatusPayment(bool);
    },
    updatePaymentLoader(data) {
      this.changePaymentLoader(data);
    },
    changeCurrentOrders(data) {
      this.currentOrderProps = data;
    },
    showErrorPaypal(val) {
      this.errorOrder = val;
    },
    showErrorStripe(val) {
      this.errorOrderStripe = val;
    },
    getTextErrorPayment(val) {
      this.textErrorPayment = val;
    },
    changeCheckBox() {
      this.openPayPal = false;
    },
    showPayPal() {
      if (this.paymentMethodActive === 1) {
        this.isOpenModalPayOfCards = !this.isOpenModalPayOfCards;
      } else {
        this.openPayPal = !this.openPayPal;
      }
    },
    async showStripe() {
      const { client, _id } = this.currentOrderProps;
      const payments = {
        clientId: client._id,
        orderId: _id,
        isPrepayment: this.calcPrepaymentCondition,
        lang: this.language,
        paymentService: 'Stripe',
      };
      const { data: { client_secret: clientSecret } } = await paymentsApi.addPaymentsCheckout(payments);
      this.clientSecret = clientSecret;
      this.isOpenStripe = true;
    },
    showAnyWayOfPay() {
      console.log('anyWay');
    },
    closePayPal() {
      this.openPayPal = false;
    },
    closeStripe() {
      this.isOpenStripe = false;
    },
    closeModalPayOfCards() {
      this.isOpenModalPayOfCards = false;
    },
    changeStatusPayment(data) {
      this.successfulPayment = data;
    },
    changePaymentLoader(data) {
      this.loaderPayment = data;
    },
    calcNum(number, time) {
      const cases = [2, 0, 1, 1, 1, 2];
      return time[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    toggleMethodActive(id) {
      this.paymentMethodActive = id;
      this.activePaySystem = 1;
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
          return require(`@/assets/images/${image}.svg`);
          /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style>
  .fakeBtn + paypal {
     pointer-events: none;
  }

</style>

<style scoped lang="scss">
  @import "../sass/variables";

  .fakeBtn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    pointer-events: none;
  }

  .paypal_container-disable {
    position: relative;
    pointer-events: all;
    width: 150px;
  }
  .paypal_wrapper-disable {
   pointer-events: none;
  }

  .service-registration {
    padding: 25px;

    &__title {
      font: $font-size-huge $font-global-bold;
      color: $color-mine-shaft;
      margin-bottom: 28px;
    }
  }

  .orders {
    background: $color-alabaster;

    &__wrapper {
      background: $color-white;
      padding-bottom: 24px;
      min-height: calc(100vh - 95px);
      border-radius: $borderRadius;
      box-shadow: 0 0 8px rgba($color-black, .04);
    }
  }

  .payment-method {
    max-width: 920px;
    margin: 0 auto 13px;

    &__title {
      font: $font-size-dd $font-global-bold;
      color: $color-black;
      text-align: center;
      margin-bottom: 14px;
    }

    &__list {
      display: flex;
      justify-content: center;
    }

    &-item {
      margin: 10px;
      width: 215px;
      height: 129px;
      border-radius: $borderRadius;
      border: 2px solid $color-gallery;
      padding: 19px 24px 39px 30px;
      cursor: pointer;
      transition: border-color .15s ease-in;
      position: relative;

      &__top {
        display: flex;
        margin-bottom: 22px;
      }

      &__image {
        display: flex;
        align-content: center;
        justify-content: center;

        & + .payment-method-item__image {
          margin-left: 15px;
        }
      }

      &__title {
        font: $font-size-xlg $font-global-medium;
        margin-bottom: 17px;
      }

      &__status {
        font-size: $font-size-dd;
        color: $color-dodger-blue;
        margin-left: auto;
        position: absolute;
        top: 21px;
        right: 19px;
      }

      &__description {
        font-size: $font-size-xlg;
        line-height: 1.15;
      }

      &:hover, &_active {
        border-color: $color-dodger-blue;
      }
    }
  }

  .payment-info {
    display: flex;
    flex-direction: column;
    max-width: 920px;
    margin: auto;
    align-items: center;
    padding: 0 10px;

    &-item {
      display: flex;
      width: 100%;
      align-items: center;
      min-height: 71px;
      border-bottom: 1px solid $color-gallery;

      &__left {
        max-width: 540px;
        margin-right: 20px;
        width: 100%;
      }

      &__right {
        display: flex;
        max-width: 360px;
        width: 100%;
        justify-content: space-between;
        position: relative;
      }

      &__title {
        font-family: $font-global-bold;
        color: $color-dodger-blue;
      }

      &_prepayment {
        border-bottom: none;

        .payment-info-item {
          &__title {
            font: $font-size-landing-medium $font-global-medium;
          }

          &__right {
            justify-content: flex-end;
          }

          &__prepayment {
            font: $font-size-landing-medium $font-global-medium;
            color: $color-dodger-blue;
          }
        }
      }

      &_finish {
        margin-top: 24px;
        border-bottom: none;

        .payment-info-item {
          &__right {
            position: relative;
            justify-content: flex-end;
            min-height: 43.8px;
          }

          &__left {
            margin-top: 3px;
          }
        }
      }
    }
  }
</style>
