<template>
  <ClientOrder v-if="isClient" />
  <PartnerOrder
    v-else-if="isPartner"
    :currentOrder="currentOrder"
  />
  <AdminOrder
    v-else-if="isManager || isAdmin"
  />
</template>

<script>
import ClientOrder from '@/components/order/clientOrder';
import PartnerOrder from '@/components/order/partnerOrder';
import AdminOrder from '@/components/order/adminOrder';
import {
  CLIENT, CEO, PARTNER, MANAGER, OWNER, DEPARTMENT_HEAD,
} from '@/constants/roleType';
import userApi from '@/api/user/userApi';
import queryString from 'query-string';

export default {
  name: 'Order',
  components: {
    ClientOrder,
    PartnerOrder,
    AdminOrder,
  },
  data() {
    return {
      currentOrder: {},
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    isAdmin() {
      return this.userRole === OWNER
          || this.userRole === CEO
          || this.userRole === MANAGER
          || this.userRole === DEPARTMENT_HEAD;
    },
    isClient() {
      return this.userRole === CLIENT;
    },
    isPartner() {
      return this.userRole === PARTNER;
    },
    isManager() {
      return this.userRole === MANAGER;
    },
  },
  async beforeMount() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ name: 'landing' });
    } else if (!Object.keys(this.userInfo).length) {
      const { location } = window;
      const parsed = queryString.parse(location.search);
      const { id } = parsed;
      if (!id) {
        this.$router.push({
          name: 'page404',
          query: {},
        });
      } else if (!Object.keys(this.userInfo).length) {
        await userApi.currentUserInfo()
          .then((resp) => {
            this.$store.dispatch('setUserInfo', resp.data);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  },
};
</script>

<style scoped lang="scss">
</style>
