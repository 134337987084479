<template>
  <div class="feedback">
    <div class="feedback__content">
      <div class="feedback__header">
        <h2 class="feedback__title">
          {{ $t('feedback.title') }}
        </h2>
      </div>
      <div
        v-if="!showFeedbackGratitude"
        class="feedback__body"
      >
        <p class="feedback__estimate">
          {{ $t('feedback.estimate') }}
        </p>
        <p class="feedback__description">
          {{ $t('feedback.description') }}
        </p>
        <div class="feedback-rate">
          <div
            class="feedback-rate__wrapper"
            @mouseleave="showCurrentRating(0)"
          >
            <StarRating
              class="feedback-rate__item"
              :padding="8"
              :activeColor="'#EEAC05'"
              :inactiveColor="'#EEEEEE'"
              :showRating="false"
              :roundedCorners="true"
              :starSize="24"
              :rating="4"
              :starPoints="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
              @current-rating="showCurrentRating"
              @rating-selected="setCurrentSelectedRating"
            />
          </div>
          <div class="feedback-rate__status">
            {{ currentRating }}
          </div>
        </div>
        <form
          class="feedback-form"
          @submit.prevent="sendComment"
        >
          <div class="feedback-form__label">
            {{ $t('feedback.label') }}
          </div>
          <div class="feedback-form__textarea">
            <textarea
              v-model="comment"
              name=""
              :class="{'error': $validator.errors.has('comment')}"
              :errorStatus="$validator.errors.has('comment')"
            >
            </textarea>
            <transition name="fade-el">
              <span
                v-show="$validator.errors.has('comment')"
                class="validation"
              >
                {{ $validator.errors.first('comment') }}
              </span>
            </transition>
          </div>
          <button
            class="ub-btn ub-btn_primary feedback-form__button"
            type="submit"
          >
            {{ $t('feedback.buttonName') }}
          </button>
        </form>
      </div>
      <transition
        name="fade-content"
        appear
        mode="out-in"
      >
        <feedbackGratitude
          v-if="showFeedbackGratitude"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import validationErrorMessage from '@/locales/validationErrorMessage';
import feedbackApi from '@/api/feedback/feedbackApi';
import feedbackGratitude from '@/components/feedback/feedbackGratitude';

export default {
  name: 'Feedback',
  components: {
    StarRating,
    feedbackGratitude,
  },
  props: {
    currentOrder: {
      type: Object,
    },
  },
  data() {
    return {
      currentRating: this.$t('rating.textFourth'),
      currentSelectedRating: this.$t('rating.textFourth'),
      showFeedbackGratitude: false,
      grade: 4,
      preGrade: 4,
      comment: '',
    };
  },
  beforeMount() {
    const dict = {
      en: {
        custom: {
          comment: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          comment: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'comment', rules: { required: true } });
  },
  methods: {
    setCurrentSelectedRating(rating) {
      this.grade = rating;
      switch (rating) {
      case 1:
        this.currentSelectedRating = this.$t('rating.textFirst');
        this.preGrade = 1;
        break;
      case 2:
        this.currentSelectedRating = this.$t('rating.textSecond');
        this.preGrade = 2;
        break;
      case 3:
        this.currentSelectedRating = this.$t('rating.textThird');
        this.preGrade = 3;
        break;
      case 4:
        this.currentSelectedRating = this.$t('rating.textFourth');
        this.preGrade = 4;
        break;
      case 5:
        this.currentSelectedRating = this.$t('rating.textFifth');
        this.preGrade = 5;
        break;
      default:
        break;
      }
    },
    showCurrentRating(rating) {
      this.grade = (rating === 0) ? this.preGrade : rating;
      switch (rating) {
      case 1:
        rating = this.$t('rating.textFirst');
        break;
      case 2:
        rating = this.$t('rating.textSecond');
        break;
      case 3:
        rating = this.$t('rating.textThird');
        break;
      case 4:
        rating = this.$t('rating.textFourth');
        break;
      case 5:
        rating = this.$t('rating.textFifth');
        break;
      default:
        break;
      }
      this.currentRating = (rating === 0) ? this.currentSelectedRating : rating;
    },
    sendComment() {
      const {
        client: { _id: clientId } = {}, _id,
      } = this.currentOrder;
      const feedback = {
        order: _id,
        client: clientId,
        comment: this.comment,
        grade: this.grade,
      };
      this.$validator.validateAll({
        comment: this.comment,
      }).then((result) => {
        if (result) {
          feedbackApi.addFeedback(feedback)
            .then(() => {
              this.showFeedbackGratitude = true;
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.feedback {
  background: $color-white;
  box-shadow: 0 0 8px rgba($color-black, .04);
  border-radius: $borderRadius;
  height: calc(100vh - 85px);

  &__header {
    min-height: 75px;
    max-height: 75px;
    border-bottom: 1px solid $color-alabaster;
    padding: 0 25px;
    display: flex;
    align-items: center;
  }

  &__title {
    font: $font-size-huge $font-global-bold;
    color: $color-mine-shaft;
  }

  &-rate {
    display: flex;
    margin-bottom: 18px;
    align-items: center;

    &__item {
      margin-right: 4px;
    }

    &__status {
      font: $font-size-landing-sm $font-global;
      color: $color-silver-chalice;
      position: relative;
      top: -3px;
    }
  }

  &__estimate {
    font: $font-size-dd $font-global-bold;
    margin-bottom: 19px;
  }

  &__description {
    font: $font-size-landing-md $font-global;
    max-width: 620px;
    width: 100%;
    margin-bottom: 26px;
  }

  &__body {
    padding: 29px 25px 25px 25px;
  }

  &-form {
    &__label {
      font: $font-size-md $font-global-medium;
      color: $color-silver-chalice;
      margin-bottom: 5px;
    }

    &__textarea {
      max-width: 640px;
      width: 100%;
      margin-bottom: 21px;
      position: relative;

      textarea {
        border: 1px solid $color-gallery;
        border-radius: $borderRadius;
        width: 100%;
        height: 120px;
        resize: none;
        padding: 12px;
        font: $font-size-base $font-global;
        transition: border-color .15s ease-in;

        &:focus {
          border-color: $color-dodger-blue;
        }

        &.error {
          border-color: $color-cardinal;
        }
      }
    }
  }
}

</style>
