import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  addFeedback(data) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.FEEDBACK_API,
    });
    return instWithCred.post('', data);
  },

  getAllFeedback({
    page = 1, perPage = 5, filter,
  }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    const sortQuery = '&sort={"createdAt": -1}';
    return instWithCred.get(`feedbacks?page=${page}&per_page=${perPage}${filterQuery}${sortQuery}`);
  },
};
