<template>
  <div class="payment-result">
    <div class="payment-result__content">
      <div class="payment-result__title">
        {{ $t('payment.paymentResult') }}
      </div>
      <div class="payment-result__image">
        <img
          src="@/assets/images/success.svg"
          alt="success"
        >
      </div>
      <div class="payment-result__subtitle">
        {{ $t('payment.successfully') }}
      </div>
      <p class="payment-result__description">
        <span>{{ $t('payment.yourOrder') }}</span>
        № {{ currentOrder.orderId }}
        <span>{{ $t('payment.orderDescription') }}</span>
      </p>
      <button
        v-if="false"
        class="ub-btn ub-btn_primary"
        @click="goToRoute"
      >
        {{ $t('payment.personalArea') }}
      </button>
    </div>
  </div>
</template>

<script>
import ordersApi from '@/api/orders/ordersApi';

export default {
  name: 'PaymentResult',
  props: {
    currentOrder: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    loaderDef: {
      get() {
        return this.loader;
      },
      set(data) {
        this.$emit('update:loader', data);
      },
    },
  },
  methods: {
    goToRoute() {
      ordersApi.getOrderById(this.currentOrder._id)
        .then((resp) => {
          this.$emit('update:currentOrder', resp.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

  .payment-result {
    padding: 0 15px 15px 15px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      font: $font-size-dd $font-global-bold;
      color: $color-black;
      margin-bottom: 22px;
    }

    &__image {
      margin-bottom: 22px;
    }

    &__subtitle {
      font: $font-size-icon-xd $font-global-bold;
      color: $color-mine-shaft;
      margin-bottom: 14px;
    }

    &__description {
      font: $font-size-h2 $font-global;
      line-height: 1.5;
      max-width: 505px;
      width: 100%;
      text-align: center;
      margin-bottom: 19px;
    }
  }
</style>
