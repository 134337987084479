<template>
  <div class="feedback-gratitude">
    <div class="feedback-gratitude__content">
      <div class="feedback-gratitude__image">
        <img
          src="@/assets/images/gratitude.svg"
          alt="gratitude"
        >
      </div>
      <div class="feedback-gratitude__title">
        {{ $t('reviews.title') }}
      </div>
      <p class="feedback-gratitude__description">
        {{ $t('reviews.description') }}
      </p>
      <button
        class="ub-btn ub-btn_primary feedback-gratitude__button"
        @click="goToOrderList"
      >
        {{ $t('reviews.buttonName') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackGratitude',
  methods: {
    goToOrderList() {
      this.$router.push({
        name: 'ordersList',
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

  .feedback-gratitude {
    height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      top: -5px;
    }

    &__image {
      max-width: 161px;
      margin-bottom: 18px;
    }

    &__title {
      font: $font-size-icon-xd $font-global-bold;
      color: $color-mine-shaft;
      margin-bottom: 15px;
    }

    &__description {
      text-align: center;
      max-width: 515px;
      width: 100%;
      font: $font-size-xlg $font-global;
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
</style>
