<template>
  <div class="payment-system">
    <h4 class="payment-system__title">
      {{ $t('payment.choosePaymentSystem') }}
    </h4>
    <div class="payment-system__row">
      <div
        v-for="method in paymentSystem"
        :key="method.id"
        class="payment-system__item"
        :class="{'payment-system__item active': method.id === activePaySystem}"
        @click="togglePaySystemActive(method.id)"
      >
        <div class="payment-system__card card">
          <div class="card__checkbox">
            <div
              v-show="method.id === activePaySystem"
              class="card__checkbox active"
            ></div>
          </div>
          <div
            v-for="image in method.image"
            :key="image.id"
            class="card__image"
          >
            <img
              :src="getOptionImage(image.urlImage)"
              :alt="image.urlImage"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paymentSystemDef from '@/constants/paymentSystem';

export default {
  name: 'PaySystem',
  props: {
    activePaySystem: {
      type: Number,
    },
  },
  data: () => ({
    paymentSystem: paymentSystemDef,
  }),
  methods: {
    togglePaySystemActive(id) {
      this.$emit('setActiveSystemPayment', id);
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/${image}.svg`);
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.payment-system {
  width: 100%;
  margin-bottom: 20px;

  &__title {
    font-size: $font-size-h4;
    color: $color-silver-chalice;
    margin-bottom: 2px;
    font-weight: 400;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
   }

  &__item {
    flex: 0 1 50%;
    padding: 10px 17px;
    border: 1px solid $color-gallery-azamat;
    border-radius: 4px;
    transition: border-color ease-in 0.2s;
    cursor: pointer;

    &:hover {
      border-color: $color-dodger-blue;
    }
  }
  &__item.active {
    border-color: $color-dodger-blue;
  }

  .card {
    display: flex;
    align-items: center;
    column-gap: 10px;

    &__checkbox {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $color-dodger-blue;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__checkbox.active {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-dodger-blue;
    }

    &__image {
      transform: translateY(2px);
    }
  }
}

</style>
