<template>
  <div class="order">
    <loader v-if="loader" />
    <div class="order-header">
      <breadСrumbs
        :items="breadCrumbs"
        :arrowIcon="true"
      />
      <orderInfo
        v-if="Object.keys(orderInfo).length"
        :orderInfo="orderInfo"
        :language="language"
        :openChat.sync="openChat"
      />
    </div>
    <template
      v-if="showOrderInfo"
    >
      <transition
        v-if="$route.params.openPayPal || showWindowWithPaypal"
        name="fade-content"
        mode="out-in"
        appear
      >
        <payment
          :stepsInfo="stepsInfo"
          :currentOrder.sync="currentOrder"
          :loader.sync="loader"
          @progressBarClickEvent="progressBarClickEvent"
        />
      </transition>
      <template v-if="$route.params.openPayPal === undefined && !showWindowWithPaypal">
        <transition
          v-if="orderStatusInfo.code === orderStatus.PROJECT_FINISHED && currentOrder.feedback === undefined"
          name="fade-content"
          mode="out-in"
          appear
        >
          <feedback
            :currentOrder="currentOrder"
          />
        </transition>
        <template v-else>
          <orderInfoProgressBar
            :currentOrder="currentOrder"
            :orderId="orderId"
            :status="orderStatusInfo"
            :language="language"
            :userRole="userRole"
            :value="progressBarStatus"
            :barClass="'progress-bar__blue'"
            :days="progressBarDays"
            :openChat.sync="openChat"
            :loader.sync="loader"
            :procentPayment="procentPayment"
            @progressBarClickEvent="progressBarClickEvent"
            @toggleShowWindowWithPaypal="toggleShowWindowWithPaypal"
          />
          <div class="table">
            <div class="table-header">
              <div
                class="table-header__order"
              >
                <div class="table-header__order-text">
                  {{ $t('orderPage.table.order') }}
                </div>
              </div>
              <div class="table-header__timing">
                {{ $t('orderPage.table.timing') }}
              </div>
              <div class="table-header__price">
                {{ $t('orderPage.table.cost') }}
              </div>
              <div class="table-header__status">
                {{ $t('orderPage.table.status') }}
              </div>
            </div>
            <div class="table-content">
              <div
                v-for="(item, index) in stepList"
                :key="item.stepId"
              >
                <div>
                  <div
                    class="table-content-row"
                  >
                    <div
                      class="table-content__order"
                    >
                      <div class="table-content__order-wrap">
                        <div
                          class="table-content__order-index"
                        >
                          {{ index + 1 }}
                        </div>
                        <div class="table-content__order-info">
                          <div>
                            {{ item.titleStep }}
                          </div>
                          <div
                            class="table-content__order_more"
                            :class="{'table-content__order_more_disable': !item.fields.length}"
                            @click="setActiveItem(item.stepId)"
                          >
                            <template
                              v-if="activeItem === item.stepId"
                            >
                              {{ $t('steps.turn') }}
                            </template>
                            <template
                              v-else
                            >
                              {{ $t('steps.details') }}
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-content__timing">
                      <template v-if="!item.isActive">
                        &mdash;
                      </template>
                      <template v-else>
                        {{ item.timing }}
                        {{
                          calcDay(item.timing, [$t('global.dayOne'), $t('global.dayAlternative'), $t('global.days')])
                        }}
                      </template>
                    </div>
                    <div class="table-content__price">
                      <template v-if="!item.isActive">
                        &mdash;
                      </template>
                      <template v-else>
                        {{ item.cost }} {{ currency }}
                      </template>
                    </div>
                    <div class="table-content__status">
                      <div
                        v-if="!item.isActive"
                        class="table-content__status-reorder"
                      >
                        <span
                          v-if="!checkPaidStatus"
                          class="table-content__status-info table-content__status-info_individually"
                        >
                          {{ $t('orderPage.byYourself') }}
                        </span>
                        <div
                          v-if="checkFileCancel"
                          @click="reOrder(item)"
                        >
                          <i class="ub-icon-cross"></i><span class="table-content__status-text">
                            {{ $t('global.reOrder') }}
                          </span>
                        </div>
                      </div>
                      <div v-else-if="item.status === 'IP'">
                        <span class="table-content__status-info table-content__status-info_process">
                          {{ $t('statuses.inProgress') }}
                        </span>
                      </div>
                      <div v-else-if="item.status === 'CM'">
                        <span class="table-content__status-info table-content__status-info_done">
                          {{ $t('statuses.completed') }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <transition
                    name="fade-el"
                    mode="out-in"
                  >
                    <div
                      v-if="activeItem === item.stepId"
                      class="table-content-more"
                    >
                      <div
                        v-if="item.documents.length"
                        class="table-content-more-section"
                      >
                        <div
                          v-for="file in item.documents"
                          :key="file.fieldId"
                        >
                          <ElementDefault
                            :element.sync="file"
                            :zIndexEl="true"
                            :step="item"
                          />
                        </div>
                      </div>
                      <div class="table-content-more-section">
                        <div
                          v-for="file in item.fields"
                          :key="file.fieldId"
                          class="table-content-file"
                          :class="{'table-content-file_event': !availableFiles}"
                        >
                          <div
                            v-if="showElement(file)"
                            class="table-content-elem"
                          >
                            <ElementDefault
                              :element.sync="file"
                              :zIndexEl="true"
                              :step="item"
                              :stepsField="status"
                              :customClass="render"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
                <transition name="fade-el">
                  <reOrderModal
                    v-if="isVisibleReOrderModal === item.stepId"
                    :item="item"
                    :order.sync="currentOrder"
                    :indexStep="index"
                    :language="language"
                    @updateRequest="updateRequest"
                    @closeModal="closeModal"
                  />
                </transition>
              </div>
            </div>
            <div class="table-amount">
              <div
                class="table-amount__order"
              >
                <div class="table-amount__order-text">
                  {{ $t('global.total') }}
                </div>
              </div>
              <div class="table-amount__timing">
                {{ allDays }} {{ calcNum }}
              </div>
              <div class="table-amount__price">
                {{ fullPrice }} {{ currency }}
              </div>
              <div class="table-amount__status"></div>
            </div>
          </div>
          <chat
            v-if="false"
            :order="currentOrder"
            :openChat.sync="openChat"
          />
        </template>
      </template>
    </template>
    <transition
      name="fade-el"
      mode="out-in"
    >
      <addressRequestModal
        v-if="showAddressRequestModal"
        @closeModal="closeAddressRequestModal"
      />
    </transition>
  </div>
</template>

<script>
import ElementDefault from '@/components/elements/DefaultTemplate';
import ordersApi from '@/api/orders/ordersApi';
import queryString from 'query-string';
import breadСrumbs from '@/components/landing/breadCrumbs';
import feedback from '@/components/feedback/feedback';
import payment from '@/pages/paymentMethod';
import chat from '@/components/chat/chat';
import orderInfo from '@/components/order/orderInfo';
import orderInfoProgressBar from '@/components/order/orderInfoProgressBar';
import reOrderModal from '@/components/modals/reOrderModal';
import loader from '@/components/loader';
import addressRequestModal from '@/components/modals/addressRequestModal';
import {
  orderPayedAfterManagerConfirmed,
  orderStatus,
  clientPayedLastPayment,
} from '@/constants/orderStatus';
import { filter, find, get } from 'lodash';
import {
  CLIENT,
} from '@/constants/roleType';

export default {
  name: 'Orders',
  components: {
    ElementDefault,
    breadСrumbs,
    feedback,
    orderInfo,
    orderInfoProgressBar,
    reOrderModal,
    payment,
    loader,
    chat,
    addressRequestModal,
  },
  data() {
    return {
      stepsInfo: [],
      showWindowWithPaypal: false,
      loader: false,
      activeItem: -1,
      currentOrderDef: {},
      isVisibleReOrderModal: -1,
      orderStatus,
      orderInfoDef: {},
      stasusesPaid: [orderStatus.CD],
      reorderCancel: [orderStatus.PR, orderStatus.AT, orderStatus.SD, orderStatus.CTD, orderStatus.PPA],
      showChatStatusList: [orderStatus.AT, orderStatus.DD],
      queryParamsIdId: {
        id: '',
      },
      clientInfo: {},
      openChat: false,
      showAddressRequestModal: false,
      isFile: false,
      status: false,
      render: 'render',
      showFiles: [orderStatus.IP, orderStatus.CNP, orderStatus.PPA, orderStatus.PR, orderStatus.SD, orderStatus.CTD,
        orderStatus.CD],
    };
  },
  computed: {
    procentPayment() {
      return get(this.currentOrder, 'prePayment', null);
    },
    checkShowFiles() {
      return this.showFiles.includes(this.codeStatus);
    },
    showPayment() {
      if (!this.currentOrder.isPrepaymentFulfilled && this.currentOrder.prePayment !== 0) {
        return true;
      }
      return false;
    },
    showChat() {
      const { _id } = this.currentOrder;
      return !!_id;
    },
    currency() {
      return this.currentOrder.currency;
    },
    checkFileCancel() {
      return !this.reorderCancel.includes(this.codeStatus);
    },
    checkPaidStatus() {
      return this.stasusesPaid.includes(this.codeStatus);
    },
    codeStatus() {
      return `${get(this.orderStatusInfo, 'code', '')}`;
    },
    completedSteps() {
      const arr = this.stepList.filter((item) => item.isActive)
        .map((el) => el.timing);
      return arr.reduce((acc, val) => acc + val, 0);
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      return this.userInfo.role;
    },
    availableFiles() {
      const { isPrepaymentFulfilled, isFullPaymentFulfilled } = this.currentOrder;
      return this.userRole === CLIENT && (isPrepaymentFulfilled || isFullPaymentFulfilled);
    },
    orderStatusInfo() {
      const { status = {} } = this.currentOrder;
      return status;
    },
    currentOrder: {
      get() {
        return this.currentOrderDef;
      },
      set(data) {
        this.currentOrderDef = data;
      },
    },
    showOrderInfo() {
      const { _id } = this.currentOrder;
      return !!_id;
    },
    orderId() {
      return this.orderInfo.orderId;
    },
    orderInfo: {
      get() {
        return this.orderInfoDef;
      },
      set(data) {
        this.orderInfoDef = data;
      },
    },
    stepList() {
      return get(this.currentOrder, `steps.${this.language}`, []);
    },
    progressBarStatus() {
      const arr = this.stepList.filter((item) => item.isActive && item.status === 'CM')
        .map((el) => ({
          isActive: el.isActive,
          status: el.status,
          timing: el.timing,
        }));
      const days = arr.reduce((acc, obj) => acc + obj.timing, 0);
      const fullProcent = this.completedSteps;
      if (days) {
        const result = (days * 100) / fullProcent;
        if (result > 100) {
          return 100;
        }
        return result;
      }
      return 0;
    },
    progressBarDays() {
      const arr = [];
      this.stepList.filter((item) => {
        if (item.isActive && item.status !== 'CM') {
          arr.push({
            timing: item.timing,
            status: item.status,
          });
        }
        return true;
      });
      return arr.reduce((acc, obj) => acc + +obj.timing, 0);
    },
    breadCrumbs() {
      return [
        { name: this.$t('orderPage.ordersList'), route: '/orders-list' },
        { name: `${this.orderText} № ${this.orderInfo.orderId}`, route: '/orders' },
      ];
    },
    orderText() {
      return this.$t('orderPage.order');
    },
    language() {
      return this.$i18n.locale;
    },
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    createdCountriesList() {
      return this.allCountriesList.filter((item) => item.isVisible);
    },
    allDays() {
      const arr = [];
      this.stepList.forEach((item) => {
        if (item.isActive) {
          arr.push({
            timing: item.timing,
          });
        }
      });
      return arr.reduce((acc, obj) => acc + +obj.timing, 0);
    },
    fullPrice() {
      const arr = [];
      this.stepList.forEach((item) => {
        if (item.isActive) {
          arr.push({
            cost: item.cost,
          });
        }
      });
      return arr.reduce((acc, obj) => acc + +obj.cost, 0);
    },
    calcNum() {
      const texts = [this.$t('global.dayOne'), this.$t('global.dayAlternative'), this.$t('global.days')];
      const number = this.allDays;
      const cases = [2, 0, 1, 1, 1, 2];
      return texts[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
  },
  watch: {
    'currentOrder.payments': function (newVal) {
      if (newVal.length) {
        this.$route.params.openPayPal = undefined;
        this.showWindowWithPaypal = false;
      }
    },
  },
  beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      id = this.queryParamsId.id,
    } = parsed;
    this.queryParamsId = {
      id,
    };
    this.getRequests(this.queryParamsId);
  },
  methods: {
    toggleShowWindowWithPaypal() {
      this.showWindowWithPaypal = !this.showWindowWithPaypal;
    },
    showElement(el) {
      if (!el.parentId.length) {
        return true;
      }
      Object.keys(this.stepsInfo).forEach((key) => {
        this.stepsInfo[key].forEach((step) => {
          this.$set(step, 'isVisible', true);
        });
      });
      const stepListFields = this.stepsInfo[this.language].reduce((acc, step) => {
        step.fields.forEach((field) => {
          field.stepId = step.stepId;
        });
        return [...acc, ...step.fields];
      }, []);
      const parentElements = filter(stepListFields, (field) => el.parentId.includes(field.fieldId));
      if (!find(parentElements, (element) => element.checked)) {
        if (!el.type) {
          this.setIsVisibleStep(el, false);
        }
        return false;
      }
      const showStatus = parentElements.some((parentElement) => {
        if (parentElement.parentId.length) {
          return this.showChildElement(parentElement);
        }
        const { stepId } = parentElement;
        const parensStep = this.stepsInfo[this.language].find((step) => step.stepId === stepId);
        return parensStep.isVisible ? parentElement.checked : false;
      });
      if (!el.type) {
        this.setIsVisibleStep(el, showStatus);
      }
      return showStatus;
    },
    setIsVisibleStep(el, isVisible) {
      const { stepId } = el;
      Object.keys(this.stepsInfo[this.language]).forEach((key) => {
        const currentStep = find(this.stepsInfo[this.language][key], (item) => item.stepId === stepId);
        currentStep.isVisible = isVisible;
      });
    },
    calcDay(number, time) {
      const cases = [2, 0, 1, 1, 1, 2];
      return time[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    updateRequest(id, order) {
      this.loader = true;
      ordersApi.updateOrderById({ id, order })
        .then((resp) => {
          this.currentOrder = resp.data;
          const { status: { code } } = this.currentOrder;
          if (code === orderStatus.PPA) {
            this.showAddressRequestModal = true;
          }
          this.loader = false;
          if (this.showChatStatusList.includes(code)) {
            this.openChat = true;
          }
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    closeModal() {
      this.isVisibleReOrderModal = -1;
    },
    reOrder(order) {
      this.isVisibleReOrderModal = order.stepId;
    },
    progressBarClickEvent() {
      const order = {};
      const { status: { code }, _id: id } = this.currentOrder;
      if (code !== orderStatus.NEW_ORDER) {
        order.transition = orderPayedAfterManagerConfirmed;
      }
      if (code === orderStatus.LAST_PAYMENT_REQUIRED
          && this.currentOrder.projectExecutor) {
        order.transition = clientPayedLastPayment;
      }
      if (code !== orderStatus.NEW_ORDER) {
        this.loader = true;
        this.updateRequest(id, order);
      }
    },
    getRequests(queryParamsId) {
      const {
        id = this.queryParamsId.id,
      } = queryParamsId;
      this.queryParamsId = {
        id,
      };
      this.$router.push({
        query: {
          id,
        },
      }).catch(() => {});
      this.loader = true;
      ordersApi.getOrderById(id)
        .then((resp) => {
          this.loader = false;
          this.currentOrder = resp.data;
          this.stepsInfo = resp.data.steps;
          this.orderInfo = {
            code: resp.data.country.code,
            name: resp.data.country.name,
            managementForm: resp.data.managementForm,
            image: `country/${resp.data.country.code}.svg`,
            orderId: resp.data.orderId,
          };
          const { status: { code } } = this.currentOrder;
          const {
            clientCountry,
            district,
            postcode,
            city,
            street,
            room,
          } = this.userInfo;
          const checkAddress = !clientCountry || !street || !city || !postcode || !district || !room;
          if (code === orderStatus.PPA) {
            if (checkAddress) {
              this.showAddressRequestModal = true;
            } else {
              this.progressBarClickEvent();
            }
          }
        }).catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    setActiveItem(id) {
      const result = this.activeItem === id ? this.activeItem = -1 : this.activeItem = id;
      return result;
    },
    closeAddressRequestModal(addressUpdate) {
      this.showAddressRequestModal = false;
      if (addressUpdate) {
        this.progressBarClickEvent();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.order {
  height: 100%;
  width: 100%;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &-info {
      display: flex;

      &__img {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        img {
          border-radius: 50%;
          width: 22px;
        }
      }

      &__text {
        color: $color-black;

        &-country {
          font-family: $font-global-bold;
        }

        &-form {
          font-size: $font-size-md;
        }
      }
    }
  }

  &__text {
    font: $font-size-base $font-global;
  }

  .cell-list {
    z-index: 0;
  }

  .table {
    margin-top: 25px;
    user-select: none;
    min-width: 1155px;

    &-header {
      width: 100%;
      background: $color-white;
      box-shadow: 0 0 8px rgba($color-black, .04);
      border-radius: $borderRadius;
      color: $color-dodger-blue;
      font: $font-size-base $font-global-medium;
      display: flex;
      justify-content: flex-start;
      padding: 14px 15px;

      &__order {
        width: 40%;
        flex-grow: 1;

        &-text {
          padding-left: 29px;
        }
      }

      &__timing {
        width: 15%;
        text-align: center;
      }

      &__price {
        width: 15%;
        text-align: right;
      }

      &__status {
        width: 30%;
        padding-left: 11%;
        min-width: 450px;
      }
    }

    &-content {
      width: 100%;

      &-file {
        margin-bottom: 19px;
        display: flex;
        align-items: center;

        &_event {
          pointer-events: none;
        }
      }

      &-elem {
        width: 100%;
      }

      &-more {
        background: $color-alabaster;
        box-shadow: 0 0 8px rgba($color-black, .04);
        border-radius: 0 0 $borderRadius $borderRadius;
        min-height: 88px;

        &-section {
          padding: 20px 36px;
          border-bottom: 1px solid $color-gallery;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      &__status {
        display: flex;
        text-align: center;
        padding-left: 11%;

        &-reorder {
          font: $font-size-base $font-global-medium;
          color: $color-dodger-blue;
          height: 29px;
          display: flex;
          align-items: center;

          i {
            margin-right: 6px;
            font-size: $font-size-xlg;
          }
        }

        &-text {
          cursor: pointer;
          transition: opacity .15s ease-in;

          &:hover {
            opacity: .8;
          }
        }

        &-info {
          height: 28px;
          padding: 0;
          border-radius: $borderRadius;
          text-align: center;
          font: $font-size-base $font-global;
          margin-right: 32px;
        }

        &-info {
          border-radius: $borderRadius;
          padding: 4px 8px;
          color: $color-silver-chalice;
          display: flex;
          align-items: center;

          &_individually {
            background: $color-alabaster;
            transition: background-color .15s ease-in;
          }

          &_process {
            background: rgba($color-dodger-blue, .08);
            color: $color-dodger-blue;
            transition: background-color .15s ease-in;
          }

          &_done {
            background: rgba($color-green, .08);
            color: $color-green;
            transition: background-color .15s ease-in;
          }
        }
      }

      &-row {
        display: flex;
        padding: 18px 15px;
        background: $color-white;
        box-shadow: 0 0 8px rgba($color-black, .04);
        border-radius: $borderRadius;
        margin-top: 8px;
        min-height: 73px;
      }

      &__order {
        width: 40%;
        flex-grow: 1;

        &-wrap {
          display: flex;
        }

        &-index {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: rgba($color-dodger-blue, .1);
          display: flex;
          justify-content: center;
          align-items: center;
          font: $font-size-sm $font-global-medium;
          color: $color-dodger-blue;
          margin-right: 10px;
        }

        &_more {
          font: $font-size-md $font-global-medium;
          color: $color-dodger-blue;
          cursor: pointer;
          margin-top: 6px;

          &_disable {
            pointer-events: none;
            color: $color-gallery;
          }
        }
      }

      &__timing {
        width: 15%;
        text-align: center;
      }

      &__price {
        width: 15%;
        text-align: right;
      }

      &__status {
        width: 30%;
        padding-left: 11%;
        min-width: 450px;
      }
    }

    &-amount {
      margin-top: 8px;
      width: 100%;
      background: $color-white;
      box-shadow: 0 0 8px rgba($color-black, .04);
      border-radius: $borderRadius;
      height: 48px;
      color: $color-dodger-blue;
      font: $font-size-base $font-global-bold;
      padding: 14px 15px;
      display: flex;
      align-items: center;

      &__order {
        width: 40%;
        flex-grow: 1;

        &-text {
          padding-left: 29px;
        }
      }

      &__timing {
        width: 15%;
        text-align: center;
      }

      &__price {
        width: 15%;
        text-align: right;
      }

      &__status {
        width: 30%;
        padding-left: 11%;
        min-width: 450px;
      }
    }
  }
}
</style>
