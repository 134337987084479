
const paymentSystemDef = [
  {
    id: 1,
    image: [
      {
        id: 1,
        urlImage: 'PayPal',
      },
    ],
  },
  {
    id: 2,
    image: [
      {
        id: 3,
        urlImage: 'stripe-text',
      },
    ],
  },
  /* {
    id: 3,
    image: [
      {
        id: 4,
        urlImage: 'robokassa',
      },
    ],
  }, */
];
export default paymentSystemDef;
